/* * {
  outline: red dashed 1px;  
} */

:root {
  --white: #ffffff;
  --red: #cb4545;
  --black-10: #f0f0f0;
  --black-40: #c6c6c6;
  --black-80: #6d6d6d;
  --black-100: #333333;
}
.qr-large-device-frame {
  max-width: 442px;
  transform: translate(-50%, -50%);
  display: none;
}
.qr-before-body {
  height: 100%;
  background: transparent;
  overflow: auto;
}
a {
  text-decoration: none !important;
}
.bttom-border,
.div-border {
  border-bottom: 1px solid var(--black-10);
}
.border-btn,
.border-btn:disabled,
.sold-out-btn,
.continue-as-guest,
.fill-btn,
.fill-btn:disabled,
.view-order-detail-btn,
.carousel-cls,
.option-div,
.search-form,
.search-input,
.search-input:focus,
.customize-img,
.selection-div1,
.set-width,
.selection-div2,
.payment-img,
.review-btn,
.powered-by-evolut,
.thank-btn {
  width: -webkit-fill-available;
}
.home-div.fix-width {
  height: -webkit-fill-available;
}
.menu-price-text,
.bal-qty-text {
  color: var(--black-80);
}
.continue-as-guest:disabled,
.fill-btn,
.border-btn,
.sold-out-btn {
  background: var(--black-40);
}
.pointer-cls,
.close-search,
.spyItemClass:hover,
.qty-class {
  cursor: pointer;
}
.shaking-button:active {
  transition: background-color 1s;
  animation: shake 0.5s;
}
.fill-btn,
.cancel-btn,
.thank-btn {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.15) !important;
  border-radius: 8px !important;
  height: 48px;
}
.fill-btn:disabled {
  border-radius: 100px !important;
  height: 45px;
}
.border-btn,
.sold-out-btn {
  padding: 8px 16px;
  height: 40px;
  border-radius: 8px;
  color: var(--white);
}
.option-div,
.continue-as-guest:disabled,
.fill-btn,
.border-btn,
.sold-out-btn,
.qty-class {
  border: 1px solid var(--black-40);
}
.option-div {
  padding: 24px 16px;
  height: auto;
  border-radius: 16px;
}
.form-control::placeholder,
.spyItemClass {
  color: var(--black-40);
}
.spyItemClass {
  border-bottom: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  background: white;
  word-wrap: break-word;
  padding: 2px 2px 2px 2px;
  font-size: 12px !important;
  min-height: 30px;
  min-width: 25%;
  text-align: center;
  color: black;
}
.active {
  color: var(--black-100);
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  border: 0;
  width: 12px;
  height: 4px;
  border-radius: 8px;
}
.top-border {
  border-top: 1px solid #f6f6f6;
}
.close-search {
  color: #d9d9d9;
}
.loader-position {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.inner-div {
  max-height: calc(100svh - 76px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white !important;
}
.title-bg img {
  max-width: 350px;
}

/* Desktop Side bar start */
.qr-large-device-left {
  max-width: 294px;
  left: calc(25% - 90px);
  transform: translate(-50%, -50%);
  display: none;
}
.qr-large-device-left .qr-large-device-text {
  line-height: 21px;
  letter-spacing: 0;
  margin: 30px 0 0 0;
}
.qr-large-device-right {
  top: 65%;
  transform: translate(50%, -50%);
  display: none;
  max-width: 348px;
}
.qr-large-device-right .qr-large-device-scan-msg {
  letter-spacing: 0;
  margin: 0 0 10px auto;
  max-width: 120px;
}
.qr-large-device-scan-box {
  max-width: 120px;
  border-radius: 10px;
}
.qr-large-device-scan-arrow {
  max-width: 229px;
  margin-top: -30%;
}
/* Desktop Side bar End */
/* Splsh Screen CSS Start */
.terms-service {
  position: fixed;
  bottom: 4vh;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.9) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}
.terms-check .form-check-input {
  height: 18px;
  width: 18px;
}
.continue-as-guest {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: var(--white);
}
.powered-by-evolut {
  height: 39px;
}
/* Splash Screen CSS End */

/* Home Screen Start */
.carosel-img-size {
  height: 130px;
  border-radius: 8px;
}
.header-cls,
.header-inner-cls {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.header-cls {
  padding: 0px 24px;
  min-height: 56px;
}
.header-cls img {
  max-height: 45px;
}
.header-inner-cls {
  padding: 20px 14px 0px;
  height: 50px;
}
.side-menu {
  height: 56px;
}
.side-menu-header {
  height: 137px;
}
.side-menu-body {
  top: 192px;
}
.right-arrow {
  color: var(--black-40);
}
.input-search-icon {
  position: absolute;
  font-size: 24px;
}
.search-input {
  padding: 10px;
  border-radius: 100px;
  padding-left: 3rem;
}

.search-input:focus {
  padding: 8px 10px;
  border-radius: 100px;
  padding-left: 3rem;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
  outline: 0 !important;
}
.scrollSpyContainer {
  float: left;
  list-style-type: none;
  width: 100%;
  position: sticky;
  overflow-y: hidden;
  overflow-x: scroll;
  background-color: white;
  /* justify-content: center; */
}
.scrollSpyContainer li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-div {
  overflow-y: scroll;
  overflow-x: hidden;
  height: -webkit-fill-available;
}
.fix-width {
  display: flow-root;
  overflow-x: hidden;
  overflow-y: scroll;
}
.carousel-cls {
  max-height: 150px;
  border-radius: 15px;
  object-fit: contain;
  margin-top: -25px;
}
.best-seller-tag {
  padding: 4px 12px;
  width: 77px;
  height: 22px;
  background: #ff9900;
  box-shadow: 3px 4px 4px rgba(51, 51, 51, 0.1);
  border-radius: 0px 8px 8px 0px;
  margin-top: 10px;
}
/* .sticky-btn {
  bottom: 30px;
} */
.pulsing-button {
  animation: pulse 1.5s ease-in-out infinite;
}
.option-icon {
  width: 56px;
  height: 56px;
  border-radius: 100px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.dining-btn {
  width: fit-content;
  height: 36px;
}
/* Home Screen End */
/* Drawer Css Start */
.EZDrawer .EZDrawer__container {
  border-radius: 16px 16px 0px 0px;
}
.dining-select-div {
  display: -webkit-box;
}
/* Drawer Css End */
/* Cart Page CSS Start */
.back-arrow {
  top: 7px;
}
.popular-img {
  width: 160px;
  height: 120px;
  border-radius: 8px;
}
.cart-img {
  width: 83px;
  height: 72px;
  border-radius: 8px;
}
.edit-btn {
  align-items: center;
  padding: 4px 15px;
  width: 71px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid #d9d9d9;
}
.qty-class {
  border-radius: 100px;
  padding: 3px;
  width: 24px;
  height: 24px;
}
.cart-button-div {
  box-shadow: 0px -2px 4px rgba(51, 51, 51, 0.15);
}
.info-icon-cls {
  color: #c6c6c6;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.info-icon-cls:focus {
  outline: none !important;
}
.styles-module_info__BWdHW {
  background-color: var(--black-40) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  border-radius: 8px;
  width: 202px;
  height: 33px;
}
.check-icon-color,
.close-icon-color {
  height: 24px;
  width: 24px;
}
.check-icon-color {
  color: #289c4f;
}
.close-icon-color {
  color: var(--red);
}
.reoder-btn {
  height: 33px;
  background: var(--white) !important;
  border-radius: 100px !important;
}
.leftbox {
  float: left;
  width: 29px;
  border-radius: 4px;
  height: 26px;
  padding: 2px 6px;
}
.middlebox {
  float: left;
  width: 120%;
}
.rightbox {
  float: right;
  width: 40%;
}
/* Cart Page css End */
/* Payment Page Start */
.payment-div {
  background: var(--black-10);
}
.apple-btn {
  background: var(--black-100);
  border-radius: 8px;
  padding: 12px 21px;
}
.pay-btn {
  background: #7d00d6;
  border-radius: 8px;
  padding: 12px 21px;
}
.payment-border {
  border-radius: 8px 8px 0px 0px;
  padding: 26px 16px;
}
.payment-border:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 33px;
  border-radius: 50%;
  background: var(--black-10);
  z-index: 3;
  top: 80%;
  left: -24px;
}

.payment-border:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 33px;
  border-radius: 50%;
  background: var(--black-10);
  z-index: 3;
  top: 80%;
  right: -24px;
}
.payment-border-bottom {
  background: var(--white);
  border-radius: 0px 0px 8px 8px;
  padding: 26px 16px;
}
.email-section {
  height: 100%;
  background: var(--white);
  border-radius: 16px 16px 0px 0px;
  min-height: 100%;
}
.send-btn {
  right: 25px;
  background: #979797 !important;
  border-radius: 5px !important;
  border-color: #979797 !important;
  height: 41px;
}
.payment-input-email {
  height: 42px;
}
.review-btn {
  background: var(--black-10) !important;
  border-radius: 8px !important;
  border-color: var(--black-10) !important;
}
.thank-btn:hover {
  background-color: white !important;
  border: white !important;
}
/* Payment page End */
/* Customize Css Start */
.customize-img {
  height: 180px;
  border-radius: 10px;
  object-fit: contain;
}
.selection-div1 {
  align-items: flex-start;
  background: var(--white);
  border-radius: 16px 16px 0px 0px;
}
/* Customize Css End */
/* Media Query Start */
@media screen and (min-width: 768px) {
  body {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .qr-large-device-frame,
  .qr-large-device-left,
  .qr-large-device-right {
    display: block;
  }
  .qr-before-body {
    max-width: 318px;
    height: 556px;
    overflow: hidden;
    position: fixed;
    top: calc(50% + 3px);
    left: 50.4%;
    transform: translate(-50%, -50%);
    z-index: 99;
    overflow-y: auto;
    border-radius: 7px;
  }
  .offcanvas.offcanvas-start {
    position: fixed;
    top: calc(50% + 2px) !important;
    left: calc(50% - 12px) !important;
    transform: translate(-50%, -50%) !important;
    height: 554px;
    width: 280px !important;
    transition: transform 0.3s ease-in-out;
  }

  .img-div-height {
    top: 49.3%;
  }
  .hotel-title {
    font-size: 23px !important;
    line-height: 36px !important;
  }
  .sticky-btn {
    bottom: 15px;
  }
  .popular-img {
    width: -webkit-fill-available;
  }
  .item {
    margin-right: 10px;
  }
  .inner-div {
    max-height: calc(66svh - 77px) !important;
  }
}

@media screen and (min-width: 280px) {
  .popular-img {
    width: -webkit-fill-available;
  }
  .item {
    margin-right: 10px;
  }
  .img-splash {
    height: 54vh !important;
  }
}
@media screen and (max-width: 280px) {
  .img-splash {
    height: 100vh !important;
  }
}

@media screen and (max-width: 550px) {
  .img-splash {
    height: 80vh !important;
  }
}
body {
  padding-bottom: env(safe-area-inset-bottom);
}
.Toastify__progress-bar--success {
  background: #07bc0c00 !important;
}

.Toastify__toast-container {
  width: 80%;
  border-radius: 50px !important;
  text-transform: uppercase;
}

.Toastify__toast-theme--light.Toastify__toast--info {
  background: navy;
  color: white;
}
.Toastify__toast-theme--light.Toastify__toast--success {
  background: #07bc0c;
  color: white;
}
.Toastify__toast-theme--light.Toastify__toast--warning {
  background: #f0ad4e;
  color: white;
}
.Toastify__toast-theme--light.Toastify__toast--error {
  background: #d9534f;
  color: white;
}
.Toastify__toast > button > svg {
  display: none;
}
.Toastify__toast-body {
  font-size: 16px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 900;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.swaying-button {
  animation: sway 1s ease-in-out infinite;
}

@keyframes sway {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shaking-button:active {
  transition: background-color 1s;
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Media Query End */

.best-seller-text,
.order-details-modifier {
  font-size: 10px;
}
.powered-by-evolut,
.search-input,
.order-date,
.order-date-text,
.order-details-price,
.order-detail-subtotal,
.payment-data,
.payment-service-text,
.payment-bill-text,
.payment-paid-text,
.payment-date,
.paymnet-paid-success-text,
.payment-email-text,
.payment-experience,
.customize-item-modi-select,
.spyItemClass {
  font-size: 12px;
}
.continue-as-guest,
.splash-text,
.no-result-desc,
.cart-price,
.menu-title,
.bal-qty-text,
.menu-price-text,
.add-cart-btn,
.sold-out-btn,
.dining-btn,
.recom-text,
.recom-price,
.add-btn,
.no-history-desc,
.order-details-desc,
.reorder-btn,
.order-details-total,
.payment-paying-text,
.apple-btn,
.pay-btn,
.paymeny-success-text,
.payment-table-text,
.payment-input-email,
.send-btn,
.review-btn,
.thank-btn,
.customize-item-price,
.customize-item-citen-name,
.customize-btn,
.modifier-text {
  font-size: 14px;
}
.menu-text,
.dine-option-text,
.table-text,
.cart-item-text,
.cart-item-price,
.cart-item-qty,
.sub-total-text,
.error-text,
.place-order-text,
.menu-btn,
.order-title,
.invoice-text,
.order-cart-qty,
.thank-desc,
.customize-item-modi-name {
  font-size: 14px;
}
.no-result-text,
.option-text,
.recommendation-text,
.order-summary-text,
.payment-summart-text,
.last-order-text,
.thank-text,
.qr-large-device-scan-msg,
.customize-item-name {
  font-size: 16px;
}
.select-option-text {
  font-size: 20px;
}
.header-icon,
.close-search,
.shopping-cart-icon,
.empty-cart-text,
.no-history-text,
.qr-large-device-text {
  font-size: 22px;
}
.qr-aprroach {
  font-size: 24px;
}
.right-arrow,
.back-arrow,
.header-title {
  font-size: 28px;
}
.qr-desc {
  font-size: 32px;
}
.qr-time-out-text {
  font-size: 54px;
}
.splash-text,
.powered-by-evolut,
.menu-text,
.search-input,
.no-result-desc,
.bal-qty-text,
.menu-price-text,
.recom-price,
.cart-item-qty,
.no-history-desc,
.order-date,
.order-date-text,
.order-details-modifier,
.order-details-price,
.order-detail-subtotal,
.payment-data,
.payment-service-text,
.paymeny-success-text,
.payment-date,
.payment-email-text,
.payment-experience,
.qr-large-device-scan-msg {
  font-weight: 400;
}
.qr-aprroach {
  font-weight: 500;
}
.customize-item-modi-name {
  font-weight: 600;
}
.continue-as-guest,
.header-title,
.no-result-text,
.cart-price,
.spyItemClass,
.best-seller-text,
.menu-title,
.add-cart-btn,
.sold-out-btn,
.select-option-text,
.option-text,
.dining-btn,
.recommendation-text,
.recom-text,
.add-btn,
.dine-option-text,
.table-text,
.order-summary-text,
.cart-item-text,
.modifier-text,
.cart-item-price,
.payment-summart-text,
.sub-total-text,
.place-order-text,
.empty-cart-text,
.menu-btn,
.no-history-text,
.last-order-text,
.order-title,
.invoice-text,
.order-cart-qty,
.order-details-desc,
.reorder-btn,
.order-details-total,
.payment-bill-text,
.payment-paying-text,
.apple-btn,
.pay-btn,
.payment-table-text,
.payment-paid-text,
.paymnet-paid-success-text,
.send-btn,
.review-btn,
.thank-text,
.thank-desc,
.thank-btn,
.qr-time-out-text,
.qr-desc,
.customize-item-name,
.customize-item-price,
.customize-item-modi-select,
.customize-item-citen-name,
.customize-btn {
  font-weight: 700;
}

.continue-as-guest,
.splash-text,
.powered-by-evolut,
.menu-text,
.header-title,
.search-input,
.no-result-text,
.no-result-desc,
.cart-price,
.spyItemClass,
.bal-qty-text,
.menu-price-text {
  font-style: normal;
}
.best-seller-text,
.sold-out-btn {
  line-height: 14px;
}
.continue-as-guest,
.powered-by-evolut,
.search-input,
.spyItemClass,
.bal-qty-text,
.menu-price-text,
.add-cart-btn,
.table-text,
.modifier-text,
.cart-item-price,
.cart-item-qty,
.sub-total-text,
.place-order-text,
.order-date,
.order-date-text,
.order-details-modifier,
.order-details-price,
.order-detail-subtotal,
.payment-data,
.payment-service-text,
.payment-bill-text,
.payment-paid-text,
.payment-date,
.paymnet-paid-success-text,
.payment-email-text,
.error-text,
.payment-experience {
  line-height: 17px;
}
.splash-text,
.header-title,
.no-result-desc,
.menu-title,
.add-btn,
.dine-option-text,
.menu-btn,
.no-history-desc,
.order-details-desc,
.order-details-total,
.pay-btn,
.paymeny-success-text,
.payment-input-email,
.thank-desc,
.thank-btn,
.qr-large-device-scan-msg,
.customize-item-citen-name {
  line-height: 20px;
}
.menu-text,
.dining-btn,
.cart-item-text,
.order-title,
.invoice-text,
.reorder-btn,
.payment-paying-text,
.apple-btn,
.payment-table-text,
.send-btn,
.review-btn,
.customize-btn {
  line-height: 22px;
}
.no-result-text,
.cart-price,
.select-option-text,
.option-text,
.recommendation-text,
.order-summary-text,
.payment-summart-text,
.last-order-tex,
.thank-textt,
.customize-item-price,
.customize-item-modi-name,
.customize-item-modi-select {
  line-height: 25px;
}
.empty-cart-text,
.no-history-text,
.qr-aprroach {
  line-height: 34px;
}
.qr-desc {
  line-height: 45px;
}
.menutitle {
  font-size: 14px;
}
.menuitems-2col {
  font-size: 14px;
}
.menuitems-staggered {
  font-size: 14px;
}
.carousel-container {
  /* max-height: 120px; */
  background-color: white;
  z-index: 999;
  opacity: 1;
  /* shrink height by 50% */
}
.menu-cls {
  background-color: white;
  z-index: 999;
  opacity: 1;
}
.menuitem {
  width: 45%;
  margin: 0 5px 0 5px;
  /* max-height : 325px; */
  background-color: white;
}
.menuitem img {
  height: 50%;
}
.menuitems {
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
}
.home-bottom-div {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.viewcart-btn {
  border: 2px solid #000000;
  color: white;
}
.react-multi-carousel-item img {
  max-height: 60px;
  object-fit: contain;
}
